
import { defineComponent } from "vue";

export default defineComponent({
  name: "LargeButton",
  props: {
    type: String,
    disabled: Boolean,
    loading: Boolean,
  },
});
