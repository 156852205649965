
import axios from "axios";
import { defineComponent } from "vue";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { videos } from "@/utils/videos";
import TextField from "@/components/TextField.vue";
import { vote } from "@/lib/aws-cognito";
import { mapActions } from "vuex";

export default defineComponent({
  name: "StadiumBackground",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    TextField,
  },
  data() {
    return {
      errors: {
        email: "",
        phone: "",
      },
      videos,
      defaultOptions: null as unknown,
      show: false,
      voteModal: false,
      voteSuccess: false,
      curChar: 300,
      linkIframe: null as unknown,
      formVote: {
        videoId: null as unknown,
        text: "",
        name: "",
        email: "",
        Phone: "",
        accept: false,
      },
      breakpoints: {
        350: {
          itemsToShow: 1.5,
        },
        1024: {
          itemsToShow: 3.5,
        },
      },
    };
  },
  methods: {
    ...mapActions("auth", ["vote"]),
    closeModal(): void {
      this.show = false;
    },
    showModal(e: string): void {
      this.show = true;
      this.voteModal = false;
      this.linkIframe = e;
    },
    showVoteModal(e: number): void {
      this.formVote.videoId = e;
      this.show = true;
      this.voteModal = true;
    },
    validateEmail() {
      if (!this.formVote.email) {
        return (this.errors.email = "Preenchimento Obrigatório");
      }

      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValidEmail = re.test(String(this.formVote.email).toLowerCase());

      this.errors.email = isValidEmail ? "" : "E-mail inválido";
    },
    validatePhone() {
      if (!this.formVote.Phone) {
        return (this.errors.phone = "Preenchimento Obrigatório");
      }

      const re = /^[1-9]{2} [2-9][0-9]{4} [0-9]{4}$/;
      const isValidPhone = re.test(String(this.formVote.Phone));

      this.errors.phone = isValidPhone ? "" : "Telefone inválido";
    },
    countChars(e: string): void {
      const maxChar = 300;
      this.curChar = maxChar - e.length;
      if (this.curChar > maxChar) return;
      this.formVote.text = e;
    },
    async voteThis(): Promise<void> {
      this.show = true;
      const callback = await this.vote({
        videoId: this.formVote.videoId,
        UserId: this.formVote.email,
        text: this.formVote.text,
        Name: this.formVote.name,
        Phone: this.formVote.Phone,
        accepted: this.formVote.accept,
      });
      if (callback.status === 200) this.voteSuccess = true;
    },
  },
  mounted() {
    // if (!localStorage.getItem("userSub")) this.$router.push("/");
    // axios.get("/optimized.json").then((res) => {
    //   this.defaultOptions = { animationData: res.data };
    // });
  },
});
