import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "type", "value", "name", "required"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_cleave = _resolveDirective("cleave")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass([_ctx.error ? 'text-red-500' : 'text-primary']),
          for: _ctx.id
        }, _toDisplayString(_ctx.required ? "*" : "") + _toDisplayString(_ctx.label), 11, _hoisted_2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", {
      id: _ctx.id,
      type: _ctx.type,
      value: _ctx.modelValue,
      name: _ctx.name,
      required: _ctx.required,
      class: _normalizeClass(["h-14 py-4 px-5 border-transparent border-2 rounded-md", { 'border-red-500': _ctx.error }]),
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur')))
    }, null, 42, _hoisted_3), [
      [_directive_cleave, _ctx.cleave || {}]
    ]),
    _createElementVNode("span", {
      class: _normalizeClass([{ invisible: !_ctx.error }, "h-4 text-red-500"])
    }, _toDisplayString(_ctx.error), 3)
  ]))
}