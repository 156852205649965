
import { defineComponent } from "vue";
import CarouselBackground from "@/components/CarouselBackground.vue";
import { mapState } from "vuex";

export default defineComponent({
  components: {
    CarouselBackground,
  },
  name: "Home",
  data() {
    return {
      step: "landing",
      showBackground: null as unknown as boolean,
    };
  },
  computed: {
    ...mapState("auth", ["loadingSignUp", "errorSignUp"]),
  },
  methods: {
    onWindowResize() {
      this.showBackground = window.innerWidth > 768;
    },
  },
  mounted() {
    this.onWindowResize();
    window.addEventListener("resize", this.onWindowResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onWindowResize);
  },
});
