import axios, { AxiosResponse } from "axios";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
let localVue: any;
const poolData = {
  UserPoolId: "sa-east-1_YTRDQ6vx0", // Your user pool id here
  ClientId: "5bnbl16puk2m4gmm6i4enlu8ad", // Your client id here
};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

const authCallBack = {
  onSuccess: function (result: any) {
    localStorage.setItem("userSub", result.getAccessToken().getJwtToken());
    localStorage.setItem("cliId", result.accessToken.payload.client_id);
    document.cookie = `token=${result
      .getAccessToken()
      .getJwtToken()};domain=neoquimicaarenavirtual`;
    localVue.loginResponse = 200;
  },
  onFailure: function (error: any) {
    console.log(error);
    if (
      error
        .toString()
        .indexOf("NotAuthorizedException: Incorrect username or password.") !==
      -1
    ) {
      localVue.loginResponse = 404;
      return;
    }
    if (
      error
        .toString()
        .indexOf("NotAuthorizedException: Password attempts exceeded") !== -1
    ) {
      localVue.loginResponse = 409;
      return;
    }
    if (
      error
        .toString()
        .indexOf("UserNotConfirmedException: User is not confirmed.") !== -1
    ) {
      localVue.loginResponse = 406;
      return;
    }
    localVue.loginResponse = 500;
  },
};

export const signUp = ({
  email,
  password,
  phone,
}: {
  email: string;
  password: string;
  phone: string;
}): Promise<AmazonCognitoIdentity.CognitoUser> => {
  const emailUserAttribute = new AmazonCognitoIdentity.CognitoUserAttribute({
    Value: email,
    Name: "email",
  });
  const phoneUserAttribute = new AmazonCognitoIdentity.CognitoUserAttribute({
    Value: phone,
    Name: "phone_number",
  });
  const nameUserAttribute = new AmazonCognitoIdentity.CognitoUserAttribute({
    Value: Math.random().toString(36).substring(7),
    Name: "name",
  });

  return new Promise((resolve, reject) => {
    userPool.signUp(
      email,
      password,
      [emailUserAttribute, phoneUserAttribute, nameUserAttribute],
      null as any,
      function (err, result) {
        if (err) {
          reject(err.message);
          console.error(err);
          return;
        }
        const cognitoUser = result?.user;
        const tokenUser = result?.userSub ?? "";
        localStorage.setItem("userSub", tokenUser);
        resolve(cognitoUser!);
      }
    );
  });
};

export async function resetPassword(username: string, vue: any) {
  localVue = vue;
  // const poolData = { UserPoolId: xxxx, ClientId: xxxx };
  // userPool is const userPool = new AWSCognito.CognitoUserPool(poolData);

  // setup cognitoUser first
  console.log(username, "reset");

  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: username,
    Pool: userPool,
  });
  // call forgotPassword on cognitoUser
  await cognitoUser.forgotPassword({
    onSuccess: function (result) {
      console.log("call result: " + result);
      localVue.resetResponse = 200;
    },
    onFailure: function (err) {
      localVue.resetResponse = err;
    },
  });
}

export function confirmPassword(
  username: string,
  verificationCode: string,
  newPassword: string,
  vue: any
) {
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
    Username: username,
    Pool: userPool,
  });

  localVue = vue;

  return new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onFailure(err) {
        localVue.resetConfirmResponse = err;
      },
      onSuccess() {
        localVue.resetConfirmResponse = 200;
      },
    });
  });
}

export const signIn = ({
  email,
  password,
  vue,
}: {
  email: string;
  password: string;
  vue: any;
}): Promise<AmazonCognitoIdentity.CognitoUser> => {
  return new Promise((resolve, reject) => {
    localVue = vue;
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const authenticationDetails =
      new AmazonCognitoIdentity.AuthenticationDetails({
        Username: email,
        Password: password,
      });
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    const res = cognitoUser.authenticateUser(
      authenticationDetails,
      authCallBack
    );
  });
};

export const completeSignup = ({
  name,
  phone,
  token,
  vue,
}: {
  name: string;
  phone: string;
  token: string;
  vue: any;
}): Promise<AmazonCognitoIdentity.CognitoUser> => {
  return new Promise((resolve, reject) => {
    localVue = vue;
    const params = {
      AccessToken: token /* required */,
      UserAttributes: [
        /* required */
        {
          Name: "name" /* required */,
          Value: name,
        },
        {
          Name: "phone" /* required */,
          Value: phone,
        },
      ],
    };

    const phoneUserAttribute = new AmazonCognitoIdentity.CognitoUserAttribute({
      Value: phone,
      Name: "phone_number",
    });
    const nameUserAttribute = new AmazonCognitoIdentity.CognitoUserAttribute({
      Value: name,
      Name: "name",
    });

    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.updateAttributes(
        [nameUserAttribute, phoneUserAttribute],
        function (err, result) {
          // Error! - not authenticated
          console.log(err, result);
        }
      );
    }
  });
};

export const vote = ({
  videoId,
  UserId,
  text,
  Name,
  Phone,
  accepted,
  acceptedSMS,
}: {
  videoId: string;
  UserId: string;
  Name: string;
  Phone: string;
  text: string;
  accepted: string;
  acceptedSMS: string;
}): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    // Send vote with axios.
    try {
      const vote = axios.post(
        `https://4rcsvcvoxb.execute-api.sa-east-1.amazonaws.com/setVote`,
        {
          VideoId: videoId,
          UserId: UserId,
          Text: text,
          Name: Name,
          Phone: Phone,
          Accepted: accepted,
          AcceptedSMS: acceptedSMS,
        }
      );
      resolve(vote);
    } catch (error) {
      reject(error);
    }
  });
};
