import { Module } from "vuex";
import { StateType } from "./index";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  signUp,
  signIn,
  completeSignup,
  vote,
  confirmPassword,
  resetPassword,
} from "@/lib/aws-cognito";

export type AuthStateType = {
  login: {
    loading: boolean;
    error: null | unknown;
    data: unknown;
  };

  checkEmail: {
    loading: boolean;
    error: null | unknown;
    valid: null | boolean;
  };

  loadingSignUp: boolean;
  errorSignUp: null | boolean;
};

const auth: Module<AuthStateType, StateType> = {
  namespaced: true,

  state: {
    login: {
      loading: false,
      error: null,
      data: null,
    },

    loadingSignUp: false,
    errorSignUp: null,

    checkEmail: {
      loading: false,
      error: null,
      valid: null,
    },
  },

  getters: {
    isLogged(state) {
      return !!state.login.data;
    },
  },

  mutations: {
    startLogin(state) {
      state.login.loading = true;
      state.login.error = null;
      state.login.data = null;
    },
    successLogin(state, data) {
      state.login.loading = false;
      state.login.error = null;
      state.login.data = data;
    },
    failLogin(state, error) {
      state.login.loading = false;
      state.login.error = error;
      state.login.data = null;
    },

    startSignUp(state) {
      state.loadingSignUp = true;
      state.errorSignUp = null;
    },
    successSignUp(state) {
      state.loadingSignUp = false;
    },
    failSignUp(state, error) {
      state.loadingSignUp = false;
      state.errorSignUp = error;
    },
  },

  actions: {
    async login(
      context,
      payload: { email: string; password: string; vue: any }
    ) {
      await context.commit("startLogin");

      try {
        await signIn({
          email: payload.email,
          password: payload.password,
          vue: payload.vue,
        });
        context.commit("successLogin");
      } catch (error) {
        context.commit("failLogin", error);
      }
    },

    async resetPass(context, payload: { username: string; vue: any }) {
      try {
        await resetPassword(payload.username, payload.vue);
      } catch (error) {
        console.log(error);
      }
    },

    async resetConfirmPass(
      context,
      payload: {
        username: string;
        vue: any;
        verificationCode: string;
        newPassword: string;
      }
    ) {
      try {
        await confirmPassword(
          payload.username,
          payload.verificationCode,
          payload.newPassword,
          payload.vue
        );
      } catch (error) {
        console.log(error);
      }
    },

    async completeSignup(
      context,
      payload: { token: string; phone: string; name: string; vue: any }
    ) {
      try {
        await completeSignup({
          token: payload.token,
          phone: payload.phone,
          name: payload.name,
          vue: payload.vue,
        });
        context.commit("successLogin");
      } catch (error) {
        context.commit("failLogin", error);
      }
    },

    async signUp(
      context,
      payload: { email: string; password: string; phone: string }
    ) {
      if (context.state.loadingSignUp) {
        return;
      }

      await context.commit("startSignUp");

      try {
        const response = await signUp({
          email: payload.email,
          phone: payload.phone,
          password: payload.password,
        });
        context.commit("successSignUp");
        context.commit("successLogin");
      } catch (error) {
        console.log(error, "erro");
        context.commit("failSignUp", error);
        return error;
      }
    },

    async vote(
      context,
      payload: {
        videoId: string;
        UserId: string;
        text: string;
        Name: string;
        Phone: string;
        accepted: string;
        acceptedSMS: string;
      }
    ) {
      if (context.state.loadingSignUp) {
        return;
      }

      await context.commit("startVote");

      try {
        return await vote({
          videoId: payload.videoId,
          UserId: payload.UserId,
          Name: payload.Name,
          Phone: payload.Phone,
          text: payload.text,
          accepted: payload.accepted,
          acceptedSMS: payload.acceptedSMS,
        });
      } catch (error) {
        context.commit("failVote", error);
        return error;
      }
    },
  },
};

export default auth;
