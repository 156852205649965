import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-cf0dca9c")
const _hoisted_1 = { class: "container relative w-full" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie = _resolveComponent("lottie")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.defaultOptions)
      ? (_openBlock(), _createBlock(_component_lottie, {
          key: 0,
          class: "w-full h-full absolute inset",
          options: _ctx.defaultOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ]))
}