import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["type"]
const _hoisted_2 = {
  key: 0,
  class: "fa fa-spinner fa-spin",
  style: {"margin-right":"15px","margin-left":"-10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["text-black hover:bg-primary-600 transition-colors uppercase h-12 md:h-16 h-16 w-full font-semibold tracking-widest", _ctx.disabled ? 'bg-primary-300 pointer-events-none' : 'bg-primary']),
    type: _ctx.type,
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("i", _hoisted_2))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ], 10, _hoisted_1))
}