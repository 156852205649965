export const videos = [
  {
    id: 1,
    thumbnail: "/thumb/AXPgTBDU5dI.jpg",
    iframe: "https://www.youtube.com/embed/I7yHTEwbqp4",
  },
  {
    id: 2,
    thumbnail: "/thumb/YR8dsnrExiI.jpg",
    iframe: "https://www.youtube.com/embed/mNRSBiXDn5A",
  },
  {
    id: 3,
    thumbnail: "/thumb/12 GUEDES copy 2.png",
    iframe: "https://www.youtube.com/embed/xgVpGQD2M9Y",
  },
  // Gols antigos. Guardar só por segurança.
  // {
  //   id: 11,
  //   thumbnail: "/thumb/5cgrB0mEPfU.jpg",
  //   iframe: "https://www.youtube.com/embed/5cgrB0mEPfU",
  // },
  // {
  //   id: 11,
  //   thumbnail: "/thumb/SYFHkeVJ3YI.jpg",
  //   iframe: "https://www.youtube.com/embed/SYFHkeVJ3YI",
  // },
  // {
  //   id: 11,
  //   thumbnail: "/thumb/na4rVoDXoEo.jpg",
  //   iframe: "https://www.youtube.com/embed/na4rVoDXoEo",
  // },
  // {
  //   id: 11,
  //   thumbnail: "/thumb/vAkwGsE7fBU.jpg",
  //   iframe: "https://www.youtube.com/embed/vAkwGsE7fBU",
  // },
  // {
  //   id: 11,
  //   thumbnail: "/thumb/XPFa80EStIA.jpg",
  //   iframe: "https://www.youtube.com/embed/XPFa80EStIA",
  // },
  // {
  //   id: 11,
  //   thumbnail: "/thumb/KL7_7lyiB2I.jpg",
  //   iframe: "https://www.youtube.com/embed/KL7_7lyiB2I",
  // },
  // {
  //   id: 11,
  //   thumbnail: "/thumb/xnqFQIXt_7U.jpg",
  //   iframe: "https://www.youtube.com/embed/xnqFQIXt_7U",
  // },
  // {
  //   id: 11,
  //   thumbnail: "/thumb/N05dIkPug3Y.jpg",
  //   iframe: "https://www.youtube.com/embed/N05dIkPug3Y",
  // },
];
