
import axios from "axios";
import { defineComponent } from "vue";
import Lottie from "vue-lottie";

export default defineComponent({
  name: "StadiumBackground",
  components: {
    Lottie,
  },
  data() {
    return { defaultOptions: null as unknown };
  },
  mounted() {
    axios.get("/optimized.json").then((res) => {
      this.defaultOptions = { animationData: res.data };
    });
  },
});
