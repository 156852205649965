import { InjectionKey } from "@vue/runtime-dom";
import {
  createStore,
  Store,
  StoreOptions,
  useStore as baseUseStore,
} from "vuex";
import auth, { AuthStateType } from "./auth";

export interface StateType {
  auth: AuthStateType;
}

export const key: InjectionKey<Store<StateType>> = Symbol();

const storeOptions: StoreOptions<StateType> = {
  modules: {
    auth: auth,
  },
};

const store = createStore(storeOptions);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useStore() {
  return baseUseStore(key);
}

export default store;
