import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fixed top-0 right-0 w-1/2 bottom-0 xl:w-2/3 2xl:mr-48 -z-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lottie = _resolveComponent("lottie")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.defaultOptions)
      ? (_openBlock(), _createBlock(_component_lottie, {
          key: 0,
          options: _ctx.defaultOptions
        }, null, 8, ["options"]))
      : _createCommentVNode("", true)
  ]))
}