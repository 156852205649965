import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store, { key } from "./store";
import Cleave from "cleave.js";

const app = createApp(App);

app.directive("cleave", {
  created: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {});
  },
});

app.use(store, key).use(router).mount("#app");
