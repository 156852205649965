
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    return {
      id: Math.random().toString(36).substring(7),
    };
  },
  props: {
    label: String,
    modelValue: String,
    name: String,
    required: Boolean,
    cleave: Object,
    error: String,
    type: {
      type: String,
      default: "text",
    },
  },
});
